@font-face {
    font-family: "HelveticaNowMicro";
    font-display: auto;
    src: local(Helvetica Now Micro Thin),
        url("#{$font-path}HelveticaNowMicro-Thin.woff2") format("woff2"),
        url("#{$font-path}HelveticaNowMicro-Thin.woff") format("woff");

    font-weight: 100;
}
@font-face {
    font-family: "HelveticaNowMicro";
    font-display: auto;
    src: local(Helvetica Now Micro Light),
        url("#{$font-path}HelveticaNowMicro-Light.woff2") format("woff2"),
        url("#{$font-path}HelveticaNowMicro-Light.woff") format("woff");
    font-weight: 300;
}

@font-face {
    font-family: "HelveticaNowMicro";
    font-display: auto;
    src: local(Helvetica Now Micro Regular), 
        url("#{$font-path}HelveticaNowMicro-Regular.woff2") format("woff2"),
        url("#{$font-path}HelveticaNowMicro-Regular.woff") format("woff");
    font-weight: 400;
}

@font-face {
    font-family: "HelveticaNowMicro";
    font-display: auto;
    src: local(Helvetica Now Micro Medium),
        url("#{$font-path}HelveticaNowMicro-Medium.woff2") format("woff2"),
        url("#{$font-path}HelveticaNowMicro-Medium.woff") format("woff");
    font-weight: 500;
}

@font-face {
    font-family: "HelveticaNowMicro";
    font-display: auto;
    src: local(Helvetica Now Micro Bold),
        url("#{$font-path}HelveticaNowMicro-Bold.woff2") format("woff2"),
        url("#{$font-path}HelveticaNowMicro-Bold.woff") format("woff");
    font-weight: 700;
}
