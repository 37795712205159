/* ==========================================================================
   $BASE-PICKER
   ========================================================================== */
/**
 * Note: the root picker element should *NOT* be styled more than what's here.
 */
.picker {
  font-size: 16px;
  text-align: left;
  line-height: 1.2;
  color: #000000;
  position: absolute;
  z-index: 10000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
}
/**
 * The picker input element.
 */
.picker__input {
  cursor: default;
}
/**
 * When the picker is opened, the input element is "activated".
 */
.picker__input.picker__input--active {
  border-color: #0089ec;
}
/**
 * The holder is the only "scrollable" top-level container element.
 */
.picker__holder {
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/*!
 * Default mobile-first, responsive styling for pickadate.js
 * Demo: http://amsul.github.io/pickadate.js
 */
/**
 * Note: the root picker element should *NOT* be styled more than what's here.
 */
/**
 * Make the holder and frame fullscreen.
 */
.picker__holder,
.picker__frame {
  bottom: 0;
  left: 0;
  right: 0;
  top: 100%;
}
/**
 * The holder should overlay the entire screen.
 */
.picker__holder {
  position: fixed;
  -webkit-transition: background 0.15s ease-out, top 0s 0.15s;
  -moz-transition: background 0.15s ease-out, top 0s 0.15s;
  transition: background 0.15s ease-out, top 0s 0.15s;
  -webkit-backface-visibility: hidden;
}
/**
 * The frame that bounds the box contents of the picker.
 */
.picker__frame {
  position: absolute;
  margin: 0 auto;
  min-width: 256px;

//  picker width
  width: 300px;
  max-height: 350px;

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
@media (min-height: 28.875em) {
  .picker__frame {
    overflow: visible;
    top: auto;
    bottom: -100%;
    max-height: 80%;
  }
}
@media (min-height: 40.125em) {
  .picker__frame {
    margin-bottom: 7.5%;
  }
}
/**
 * The wrapper sets the stage to vertically align the box contents.
 */
.picker__wrap {
  display: table;
  width: 100%;
  height: 100%;
}
@media (min-height: 28.875em) {
  .picker__wrap {
    display: block;
  }
}
/**
 * The box contains all the picker contents.
 */
.picker__box {
  background: #ffffff;
  display: table-cell;
  vertical-align: middle;
}
//@media (min-height: 26.5em) {
//  .picker__box {
////    font-size: 1.25em;
//  }
//}
@media (min-height: 28.875em) {
  .picker__box {
    display: block;

//    picker header font-size
//    font-size: 1rem;

    border: 1px solid #777777;
    border-top-color: #898989;
    border-bottom-width: 0;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
    -webkit-box-shadow: 0 12px 36px 16px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 12px 36px 16px rgba(0, 0, 0, 0.24);
    box-shadow: 0 12px 36px 16px rgba(0, 0, 0, 0.24);
  }
}
//@media (min-height: 40.125em) {
//  .picker__box {
//    font-size: 1.1rem;
//    border-bottom-width: 1px;
//    -webkit-border-radius: 5px;
//    -moz-border-radius: 5px;
//    border-radius: 5px;
//  }
//}
/**
 * When the picker opens...
 */
.picker--opened .picker__holder {
  top: 0;
  background: transparent;
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#1E000000,endColorstr=#1E000000)";
  zoom: 1;
  background: rgba(0, 0, 0, 0.32);
  -webkit-transition: background 0.15s ease-out;
  -moz-transition: background 0.15s ease-out;
  transition: background 0.15s ease-out;
}
.picker--opened .picker__frame {
  top: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}
@media (min-height: 35.875em) {
  .picker--opened .picker__frame {
    top: 10%;
    bottom: auto;
  }
}
/**
 * For `large` screens, transform into an inline picker.
 */

/* ==========================================================================
   CUSTOM MATERIALIZE STYLES
   ========================================================================== */

.picker__input.picker__input--active {
  border-color: color("blue", "lighten-5");
}

.picker__frame {
  margin: 0 auto;
  max-width: 325px;
}

@media (min-height: 38.875em) {
  .picker--opened .picker__frame {
    top: 10%;
    bottom: auto;
  }
}

@media only screen and (min-width: 601px) {
	.picker__box {
		display:flex;
	}
	.picker__frame {
		width: 80%;
		max-width:600px;
	}
}
