/* ==========================================================================
   $BASE-TIME-PICKER
   ========================================================================== */
/**
 * The list of times.
 */
.picker__list {
  list-style: none;
  padding: 0.75em 0 4.2em;
  margin: 0;
}
/**
 * The times on the clock.
 */
.picker__list-item {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  margin-bottom: -1px;
  position: relative;
  background: #fff;
  padding: .75em 1.25em;
}
@media (min-height: 46.75em) {
  .picker__list-item {
    padding: .5em 1em;
  }
}
/* Hovered time */
.picker__list-item:hover {
  cursor: pointer;
  color: #000;
  background: #b1dcfb;
  border-color: #0089ec;
  z-index: 10;
}
/* Highlighted and hovered/focused time */
.picker__list-item--highlighted {
  border-color: #0089ec;
  z-index: 10;
}
.picker__list-item--highlighted:hover,
.picker--focused .picker__list-item--highlighted {
  cursor: pointer;
  color: #000;
  background: #b1dcfb;
}
/* Selected and hovered/focused time */
.picker__list-item--selected,
.picker__list-item--selected:hover,
.picker--focused .picker__list-item--selected {
  background: #0089ec;
  color: #fff;
  z-index: 10;
}
/* Disabled time */
.picker__list-item--disabled,
.picker__list-item--disabled:hover,
.picker--focused .picker__list-item--disabled {
  background: #f5f5f5;
  border-color: #f5f5f5;
  color: #ddd;
  cursor: default;
  border-color: #ddd;
  z-index: auto;
}
/**
 * The clear button
 */
.picker--time .picker__button--clear {
  display: block;
  width: 80%;
  margin: 1em auto 0;
  padding: 1em 1.25em;
  background: none;
  border: 0;
  font-weight: 500;
  font-size: .67em;
  text-align: center;
  text-transform: uppercase;
  color: $timepicker-clock-color;
}
.picker--time .picker__button--clear:hover,
.picker--time .picker__button--clear:focus {
  color: #000;
  background: #b1dcfb;
  background: #ee2200;
  border-color: #ee2200;
  cursor: pointer;
  color: #fff;
  outline: none;
}
.picker--time .picker__button--clear:before {
  top: -0.25em;
  color: $timepicker-clock-color;
  font-size: 1.25em;
  font-weight: bold;
}
.picker--time .picker__button--clear:hover:before,
.picker--time .picker__button--clear:focus:before {
  color: #fff;
}

/* ==========================================================================
   $DEFAULT-TIME-PICKER
   ========================================================================== */
/**
 * The frame the bounds the time picker.
 */
.picker--time .picker__frame {
  min-width: 256px;
  max-width: 320px;
}
/**
 * The picker box.
 */
.picker--time .picker__box {
  font-size: 1em;
  background: #f2f2f2;
  padding: 0;
}
@media (min-height: 40.125em) {
  .picker--time .picker__box {
    margin-bottom: 5em;
  }
}

/* ==========================================================================
   $DEFAULT-TIME-PICKER
   ========================================================================== */
.clockpicker-display {
	font-size: 4rem;
	font-weight: bold;
	text-align: center;
	color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
	clear: both;
	position: relative;
}

.clockpicker-span-am-pm {
  font-size: 1.3rem;
  position: absolute;
  right: 1rem;
  bottom: 0.3rem;
  line-height: 2rem;
  font-weight: 500;
}
@media only screen and (min-width: 601px) {
	.clockpicker-display {
		top: 32%;
	}
	.clockpicker-span-am-pm {
	  position: relative;
	  right: auto;
	  bottom: auto;
	  text-align: center;
	  margin-top: 1.2rem;
	}
}


.text-primary{
	color: rgba(255, 255, 255, 1)
}
.clockpicker-span-hours {
  margin-right: 3px;
}
.clockpicker-span-minutes {
  margin-left: 3px;
}

.clockpicker-span-hours,
.clockpicker-span-minutes,
.clockpicker-span-am-pm div {
	cursor: pointer;
}
.clockpicker-moving {
	cursor: move;
}
.clockpicker-plate {
	background-color: $timepicker-clock-plate-bg;
	border-radius: 50%;
	width: 270px;
	height: 270px;
	overflow: visible;
	position: relative;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 5px;
	user-select: none;
}
.clockpicker-canvas,
.clockpicker-dial {
	width: 270px;
	height: 270px;
	position: absolute;
	left: -1px;
	top: -1px;
}
.clockpicker-minutes {
	visibility: hidden;
}
.clockpicker-tick {
	border-radius: 50%;
	color: $timepicker-clock-color;
	line-height: 40px;
	text-align: center;
	width: 40px;
	height: 40px;
	position: absolute;
	cursor: pointer;
}
.clockpicker-tick.active,
.clockpicker-tick:hover {
	background-color: transparentize($secondary-color, .75);
}
.clockpicker-dial {
	-webkit-transition: -webkit-transform 350ms, opacity 350ms;
	-moz-transition: -moz-transform 350ms, opacity 350ms;
	-ms-transition: -ms-transform 350ms, opacity 350ms;
	-o-transition: -o-transform 350ms, opacity 350ms;
	transition: transform 350ms, opacity 350ms;
}
.clockpicker-dial-out {
	opacity: 0;
}
.clockpicker-hours.clockpicker-dial-out {
	-webkit-transform: scale(1.2, 1.2);
	-moz-transform: scale(1.2, 1.2);
	-ms-transform: scale(1.2, 1.2);
	-o-transform: scale(1.2, 1.2);
	transform: scale(1.2, 1.2);
}
.clockpicker-minutes.clockpicker-dial-out {
	-webkit-transform: scale(.8, .8);
	-moz-transform: scale(.8, .8);
	-ms-transform: scale(.8, .8);
	-o-transform: scale(.8, .8);
	transform: scale(.8, .8);
}
.clockpicker-canvas {
	-webkit-transition: opacity 175ms;
	-moz-transition: opacity 175ms;
	-ms-transition: opacity 175ms;
	-o-transition: opacity 175ms;
	transition: opacity 175ms;
}
.clockpicker-canvas-out {
	opacity: 0.25;
}
.clockpicker-canvas-bearing {
	stroke: none;
	fill: $secondary-color;
}
.clockpicker-canvas-bg {
	stroke: none;
	fill: $secondary-color;
}
.clockpicker-canvas-bg-trans {
	fill: $secondary-color;
}
.clockpicker-canvas line {
	stroke: $secondary-color;
	stroke-width: 4;
	stroke-linecap: round;
	/*shape-rendering: crispEdges;*/
}
